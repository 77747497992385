<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Applications Of Medical Marijuana
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746486/cannabis-marijuana-leaf-closeup_ae4pj6.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Applications Of Medical Marijuana
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Nov 24, 2021</p>
            </span>
          </div>
          <p>
            Marijuana or weed as it’s widely called, hitherto, is a common
            feature of pop culture. The contemporary entertainment media is
            flooded with references to marijuana, ranging from movies,
            animations, music and whatnots. The fact is that within the liberal
            and neo-conservative developed communities, the usage of marijuana
            has become largely tolerable. Whereas, before the advent of pop
            culture, marijuana was viewed in the class of harmful drugs and its
            mass usage banned. This becomes ironic as studies have shown that
            people have used marijuana, or cannabis, to treat their ailments for
            at least 3,000 years.
          </p>
          <p>
            However, with the medical world evolving consistently, new
            discoveries pertaining to marijuana have revealed that for so long
            the relevance of this so-called harmful drug might have been dimmed
            by erroneous criticisms.
          </p>

          <p class="font-weight-bold mb-3">
            What is Marijuana?
          </p>
          <p>
            According to the National Health Service of the United Kingdom,
            Marijuana is the dried leaves and flowers of the Cannabis sativa or
            Cannabis indica plant. Stronger forms of the drug include high
            potency strains - known as sinsemilla (sin-seh-me-yah), hashish
            (hash for short), and extracts.
          </p>

          <p class="font-weight-bold">
            When is marijuana safe?
          </p>
          <p>
            A lot of people take marijuana for pleasure and recreation. But a
            large number of doctors in the medical industry prefer to prescribe
            it for specific medical conditions and symptoms.
          </p>
          <p>
            Having established that marijuana can be used as a form of
            anaesthesia in the medical workspace. Many patients and medical
            practitioners have reported the pain control elements of marijuana.
            For example, in cases such as post-surgical, bone or other
            life-threatening pain problems.
          </p>
          <p>
            Many other benefits of using marijuana from relieving insomnia,
            anxiety, spasticity, and pain to treating potentially
            life-threatening conditions such as epilepsy has also been
            pinpointed.
          </p>
          <p>
            In particular, marijuana appears to ease the pain of multiple
            sclerosis, and nerve pain in general, this is very effective in
            abating the effects of Parkinson's disease as well as glaucoma not
            excluding its usefulness in abating nausea during chemotherapy and
            improving the appetite of people living with HIV/AIDS.
          </p>
          <p>
            Marijuana is also reliable in fixing problems such as nausea and
            weight loss, although there is low evidence to this submission.
          </p>
          <p>
            Specifically, drugs made from marijuana include sativex to treat
            multiple sclerosis, Dronabinal for cancer treatments, Dexanabinol
            which helps protect the brain during cardiac surgery, Ajulemic acid
            to relieve pain from arthritis, Cannabinor for bladder control and
            HU 308 for treating hypertension amongst others.
          </p>
          <p class="font-weight-bold">
            How to grow Medical Marijuana
          </p>
          <p>
            Compared to the more popular recreational marijuana, medical
            marijuana is grown differently. Marijuana for medical purposes has
            to undergo a series of tests to ensure its high quality. It is grown
            in completely enclosed areas, where it is monitored intensively by
            highly experienced cultivators or farmers to make sure the plant can
            pass the proper tests to qualify for medical use.
          </p>
          <p>
            Growing the marijuana plant indoors may also contribute to the fact
            that in many states where marijuana is legal, the proper growing
            conditions do not exist. For instance, Humboldt County in California
            is one of the few places in the United States where the conditions
            are favorable for the plant to grow outside. In the topsy-turvy
            weather patterns of the Northeast and the Midwest, the plant needs
            to be grown indoors where humidity, light, and climate can be
            constantly controlled and monitored. Otherwise, the plant would
            either not survive or be of low quality.
          </p>

          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746495/marijuana-leaves-cannabis-beautiful-background_pxmayg.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>

          <p>
            Given the several applications and uses of medical marijuana,
            economical value placed on the product has been on a steady
            increase, this makes it an attractive investment opportunity for
            farmers and investors but without appropriate equipment in place to
            mitigate the risks involved in growing the plant, investors run the
            risk of churning out substandard products.
          </p>
          <p>
            Essentially adequate investment in grow lights, fans, wet walls and
            data loggers to log the humidity and temperature of the grow area
            significantly reduces the possibility of growing products that are
            below par.
          </p>
          <p>
            Establishing a cannabis business plan takes on a slightly different
            model from that of a normative business or initiative. You might
            have to face the general challenges associated with business such as
            financing, competitive research, marketing, operations, and
            structure of ownership not taking your focus off cannabis
            regulations to ensure your business plays according to the books. To
            leave no stones unturned is best to consider the security and
            traceability of your locale, employee training and compliance
            standards, safety in the removal of waste products, transportation
            and logistics for retail, packaging, and processing, testing
            capabilities and protocols as well as ensure to put in place an
            accurate description of grow facility, operations planning, and
            production process (including equipment, soil, and fertilizers to be
            used).
          </p>
          <p>
            Some countries allow the usage of the whole-plant variant of
            cannabis for medical purposes. These countries include Australia,
            Canada, Chile, Colombia, Germany, Greece, Israel, Italy, the
            Netherlands, Peru, Poland, Portugal, Uruguay and most recently, the
            United States of America.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
import image7 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
// import image5 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
import image8 from "../../assets/blog new/pharmaceuticall.png";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image7,
        content:
          "Digitalization in the African retail pharmaceutical sector (With Shelflife by Field intelligence and mPharma)",
        date: "Sept 19, 2021",
        link:
          "/blog/digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          "Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "Sept 12, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image8,
        content:
          "Gricd works with ISN to assure product integrity and avert waste risk.",
        date: "Sept 18, 2021",
        link:
          "/blog/gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Medical Consultant Speaks On The Role of Storage In Managing Diabetes.",
                item:
                  "https://gricd.com/blog/medical-consultant-speaks-on-the-role-of-storage-in-managing-diabetes",
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
.pic-size {
  width: 28rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
